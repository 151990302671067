<template>
  <div>
    <el-tabs v-model="activeName" @tab-click="handleClick">
      <el-tab-pane label="兑换券" name="first">
        <div class="exchange">
          <!-- <div class="mantil">兑换券</div> -->
          <div class="exchange_box">
            <el-form label-position="left" ref="ruleForma" class="form-box" :model="formData" label-width="5em"
              :rules="rules" hide-required-asterisk>
              <el-form-item label="兑换码" prop="couponCode">
                <el-input v-model="formData.couponCode" placeholder="请输入兑换码" />
              </el-form-item>
              <el-form-item label="验证码" prop="code">
                <div class="dis_flex">
                  <el-input v-model="formData.code" placeholder="验证码" style="width: 270px !important" />
                  <div class="diva">
                    <img :src="codeImg" alt="验证码" @click="loadCaptcha">
                  </div>
                </div>
              </el-form-item>
            </el-form>

            <div v-throttle="2000" class="mineBut pointer" @click="send">
              确认兑换
            </div>
          </div>

          <el-dialog custom-class="myDialog" :visible.sync="modalName" width="405px">
            <div class="tishi">
              <div class="tishi-title">提示</div>
              <div v-if="ifSucces" class="tishi-tips">
                <i class="iconfont succes icon-a-zu3" />{{ message }}
              </div>
              <div v-if="!ifSucces" class="tishi-tips">
                <!-- <i class="iconfont icon-a-zu-1" /> -->
                {{ message }}
              </div>
              <div class="tishi-but" @click="Iknow">确定</div>
            </div>
          </el-dialog>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import { getInfo } from '@/api/cookies'
import { imagecode } from '@/api/register'
import { CouponsClient } from '@/api/mine/couponsClient'
const couponsClient = new CouponsClient()
export default {
  components: {},
  data() {
    return {
      userInfo: getInfo(),
      codeImg: '',
      activeName: 'first',
      ifsend: true, // 是否可以发送
      formData: {
        couponCode: '',
        code: ''
      },
      /* 提交 */
      modalName: false, // 提交提示窗
      ifSucces: true, // 提交是否成功
      message: '',
      rules: {
        couponCode: [
          { required: true, message: '兑换码不能为空', trigger: 'blur' }
        ],
        code: [
          { required: true, message: '图形验证码不能为空', trigger: 'blur' },
          {
            min: 4,
            max: 4,
            message: '请输入正确的图形验证码',
            trigger: 'blur'
          }
        ]
      }
    }
  },
  created() {
    this.userInfo = getInfo()
    this.loadCaptcha()
  },
  methods: {
    loadCaptcha() {
      imagecode().then((res) => {
        this.codeImg = `data: image/jpeg;base64,${btoa(
          new Uint8Array(res).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            ''
          )
        )}`

        // this.captcha = res
      })
    },
    // 兑换
    send() {
      if (!this.ifsend) {
        return
      }
      this.$refs.ruleForma.validate(async (valid) => {
        if (valid) {
          this.ifsend = false
          couponsClient
            .studentExchange(this.formData.code, this.formData.couponCode)
            .then((data) => {
              this.modalName = true
              if (data.code == 0) {
                this.ifSucces = true
                this.message = data.msg

              } else {
                this.message = data.msg
                this.ifSucces = false
              }
            })
          this.ifsend = true
        }
      })
    },
    /* 我只到了 */
    Iknow() {
      if (this.ifSucces) {
        this.$router.push(`/seventhPage/mycurriculum`)
      } else {
        this.modalName = false
      }
    }
  }
}
</script>
<style lang="less" scoped>
.exchange {
  width: 100%;
  // min-height: 100vh;
  height: 420px;
  // background-color: #fff;
  background-image: url("~@/assets/img/partner/duihuan.png");
  background-size: 994px 412px;
  background-repeat: no-repeat;
  padding-bottom: 38px;

  // padding: 25px 30px;
  .page-title {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    font-size: 22px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #27323f;
  }

  .exchange_box {
    width: 898px;
    height: 286px;
    background: rgba(255, 255, 255, 0.7);
    border-radius: 23px 23px 23px 23px;
    opacity: 1;
    transform: translateY(88px);
    margin: 0 auto;

    /deep/ .form-box {
      padding-top: 20px;
      margin: 0px auto;
      width: 424px;

      .el-form-item {
        display: flex;
        align-items: center;
        margin-top: 40px;

        .el-form-item__label {
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #666;
          line-height: 14px;
        }

        .el-form-item__content {
          margin-left: 0px !important;
        }
      }

      .el-input {
        width: 360px;
        background: #FFFFFF;
        border-radius: 6px 6px 6px 6px;
        opacity: 1;
        border: 1px solid #DDE0E7;

        .el-input__inner {
          background: #FFFFFF;
          border: none;
          font-size: 14px;
          font-family: Microsoft YaHei-Regular, Microsoft YaHei;
          font-weight: 400;
          color: #333;
        }
      }

      .dis_flex {
        .el-input {
          width: 358px !important;
        }
      }

      .diva {
        // margin-left: 32px;
      }
    }

    /* 底部按钮 */
    .mineBut {
      width: 424px;
      height: 50px;
      background: linear-gradient(90deg, #0270e8 0%, #21b4f5 100%);
      border-radius: 6px 6px 6px 6px;
      opacity: 1;
      font-size: 16px;
      font-family: Source Han Sans CN-Normal, Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
      line-height: 50px;
      text-align: center;
      margin: 0 auto;
    }
  }

  /* 提示 */
  .tishi {
    padding-top: 20px;

    .tishi-title {
      font-size: 22px;
      font-family: PingFangSC-Regular;
      line-height: 26px;
      color: #000000;

      text-align: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
    }

    .tishi-tips {
      margin-top: 25px;
      font-size: 22px;
      font-family: PingFangSC-Regular;
      line-height: 26px;
      color: #333333;

      text-align: center;

      .iconfont {
        font-size: 23px;
        margin-right: 8px;
        color: #fa6450;
      }

      .succes {
        exchange_box color: #5ab4fc;
      }
    }

    /* 确定 */
    .tishi-but {
      width: 189px;
      height: 46px;
      background: linear-gradient(270deg, #ff5400 0%, #ff4027 100%);
      border-radius: 22px;
      font-size: 16px;
      font-family: PingFangSC-Regular;
      line-height: 46px;
      color: #ffffff;
      cursor: pointer;
      text-align: center;
      margin: 40px auto 0px;
    }
  }
}

.mantil {
  height: 104px;
  line-height: 104px;
  font-size: 24px;
  font-family: Microsoft YaHei-Bold;
  font-weight: bold;
  color: #333333;
  text-align: left;
  background-color: #f5f7f9;
}

/deep/ .el-dialog__body {
  height: 230px;
}
</style>
